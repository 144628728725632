
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import ComponentItemFormMixin from "./item-form.mixin";

@Component({
  components: {},
  mixins: [],
})
export default class NumberFormBuilder extends Mixins(ComponentItemFormMixin) {
  render() {
    const { conf, value } = this;
    return (
      <q-input
        label={`请输入${conf.label}`}
        type="number"
        dense
        filled
        value={value || ""}
        onInput={(val: any) => this.$emit("change", val)}
      />
    );
  }
}
