import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";
import { TemplateConfigNode } from "../../../../types/templates/template.interface";

@Component
export default class ComponentItemFormMixin extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public readonly conf!: TemplateConfigNode;

  @Model("change", {})
  public value!: any;
}
